




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ContactIframe',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  setup(props) {
    return {};
  }
});
