




import { defineComponent, onMounted } from '@vue/composition-api';

import ContactIframe from '@/components/contact/ContactIframe.vue';

export default defineComponent({
  components: { ContactIframe },
  name: 'Article',
  setup(props, { emit }) {
    onMounted(() => {
      emit('emitIsHomeFocus', false, true);
    });

    return {};
  }
});
